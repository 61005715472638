<template>
  <div class="app">
    <!-- 顶部卡片 -->
    <div class="header">
      <div class="header-title">
        <img src="../../assets/img/zc.png" alt="" />
        <div class="header-text">
          <p>正常还款中</p>
          <p>该贷款已放款成功，请定期还款</p>
        </div>
      </div>
      <div class="header-box">
        <!-- 内容 -->
        <div class="header-conter">
          <p style="height: 30px">
            贷款金额(元):<span>{{ Repayment.ratifyMoney }}</span>
          </p>
          <div class="header-conterflex" style="height: 30px">
            <p>
              还款方式:<span>{{ Repayment.repaymentType }}</span>
            </p>
            <p>
              贷款期数:<span>{{ Repayment.ratifyRepaymentPeriods }}期</span>
            </p>
          </div>
          <div class="header-conterflex" style="height: 30px">
            <p>
              放款时间:<span>{{ Repayment.makeLoansDate }}</span>
            </p>
            <p>
              年利率(%):<span>{{ Repayment.ratifyAnnualInterestRate }}</span>
            </p>
          </div>
        </div>
        <!-- 横线 -->
        <div class="header-line"></div>
        <!-- log -->
        <div class="header-log">
          <img src="../../assets/img/logos.png" alt="" />
        </div>
      </div>
    </div>
    <!-- Tab -->
    <div class="main">
      <van-tabs
        v-model="active"
        sticky
        line-width="20px"
        background="#F8F8F8 "
        color="#A78A48"
        title-active-color="#A78A48"
        title-inactive-color="#000000"
      >
        <van-tab title="当前应还">
          <!-- 本期应还小卡片 -->
          <div class="should">
            <div class="should-box">
              <p>本期应还(元)</p>
              <van-button
                class="should-btn"
                round
                type="info"
                :icon="require('../../assets/img/fz1.png')"
                @click="ViewLoans"
                >去还款</van-button
              >
            </div>
            <div class="should-box1">
              <p style="font-size: 18px">{{ ShouldDetails[0].amount }}</p>
              <p>还款日：{{ ShouldDetails[0].repaymentDate }}</p>
            </div>
          </div>
          <!-- 当前应还详情 -->
          <div class="details">
            <div
              v-for="(item, index) in ShouldDetails"
              :key="index"
              style="margin-bottom: 20px"
            >
              <van-field
                v-model="item.repaymentDate"
                label="还款时间:"
                input-align="right"
                readonly
              />
              <van-field
                v-model="item.amount"
                label="应还总额(元):"
                input-align="right"
                readonly
              />
              <van-field
                v-model="item.period"
                label="当前期数:"
                input-align="right"
                readonly
              >
              </van-field>
              <van-field
                v-model="item.principal"
                label="应还本金(元):"
                input-align="right"
                readonly
              />
              <van-field
                v-model="item.interest"
                label="应还利息(元):"
                input-align="right"
                readonly
              />
              <van-field label="状态:" input-align="right" readonly center>
                <template #button>
                  <van-button
                    v-if="item.repaymentStatus == 1"
                    class="repayment-button"
                    round
                    type="info"
                    size="small"
                  >
                    待还款
                  </van-button>
                  <van-button
                    v-if="item.repaymentStatus == 2"
                    class="Beoverdue-button"
                    round
                    type="info"
                    size="small"
                  >
                    已结清
                  </van-button>
                  <van-button
                    v-if="item.repaymentStatus == 3"
                    class="settle-button"
                    round
                    type="info"
                    size="small"
                  >
                    已逾期
                  </van-button>
                </template>
              </van-field>
            </div>
          </div>
        </van-tab>
        <van-tab title="还款计划表">
          <van-collapse v-model="activeName" accordion>
            <van-collapse-item
              v-for="(item, index) in schedule"
              :key="index"
              :name="index"
            >
              <template #title>
                <div class="titlebox">
                  <div class="minbox"></div>
                  <p>{{ item[0].repaymentDate.substring(0, 4) }}年</p>
                </div>
              </template>
              <div
                v-for="(_item, _index) in item"
                :key="_index"
                class="Schedule"
              >
                <div class="Schedule-period">
                  <p>{{ _item.period }}/{{ totalperiod }}期</p>
                  <p>￥{{ _item.principalFixed + _item.interestFixed }}</p>
                </div>
                <div class="Schedule-repaymentDate">
                  <p>
                    {{
                      _item.repaymentDate
                        .substring(2, 10)
                        .replace("-", "/")
                        .replace("-", "/")
                    }}
                  </p>
                  <p class="Schedule-repaymentDate-p">
                    (本金{{ _item.principalFixed }}+利息{{
                      _item.interestFixed
                    }})
                  </p>
                </div>
              </div>
            </van-collapse-item>
          </van-collapse>
        </van-tab>
      </van-tabs>
    </div>
    <!-- 查看账号弹出层 -->
    <van-popup
      v-model="show"
      closeable
      round
      close-icon="close"
      style="width: 90%"
    >
      <div class="popup-box">
        <div class="popup-title">还款账户</div>
        <div class="popup-field">
          <!-- 开户行 -->
          <van-field
            v-model="makeLoansBank.makeLoansBankOpening"
            clearable
            readonly
            autosize
            type="textarea"
            label="开户行:"
            label-width="50px"
          >
            <template #button>
              <van-button
                class="field-btn"
                size="small"
                type="primary"
                :icon="require('../../assets/img/fz.png')"
                @click="copy(makeLoansBank.makeLoansBankOpening)"
                >复制</van-button
              >
            </template>
          </van-field>
          <!-- 账户名 -->
          <van-field
            v-model="makeLoansBank.makeLoansBankName"
            clearable
            readonly
            autosize
            type="textarea"
            label="账户名:"
            label-width="50px"
          >
            <template #button>
              <van-button
                class="field-btn"
                size="small"
                type="primary"
                :icon="require('../../assets/img/fz.png')"
                @click="copy(makeLoansBank.makeLoansBankName)"
                >复制</van-button
              >
            </template>
          </van-field>
          <!-- 账号 -->
          <van-field
            v-model="makeLoansBank.makeLoansBankNumber"
            center
            clearable
            readonly
            label="账号:"
            label-width="50px"
          >
            <template #button>
              <van-button
                class="field-btn"
                size="small"
                type="primary"
                :icon="require('../../assets/img/fz.png')"
                @click="copy(makeLoansBank.makeLoansBankNumber)"
                >复制</van-button
              >
            </template>
          </van-field>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import {
  getCurrentLoanDetailList,
  getLoanDetailList,
  getCustomerOrderBank,
} from "../../api/home/home";
export default {
  data() {
    return {
      show: false,
      // Tab
      active: 0,
      // 手风琴
      activeName: "1",
      // 贷款总额详情
      Repayment: {
        applyAmount: "230000.00",
        payType: "等额本息",
        period: "60",
        createdAt: "2021-11-21",
        interestFixed: "16",
      },
      // 当前应还 详情
      ShouldDetails: [
        {
          repaymentDate: "",
        },
      ],
      // 计划表
      schedule: [],
      // 还款账号
      makeLoansBank: {},
      orderId: "",
      totalperiod: 0,
    };
  },
  methods: {
    // 查看还款账号
    ViewLoans() {
      this.show = true;
    },
    // 复制
    copy(data) {
      //创建一个input元素
      let input = document.createElement("input");
      //给input的内容复制
      input.value = data;
      // 在body里面插入这个元素
      document.body.appendChild(input);
      // 选中input里面内容
      input.select();
      //执行document里面的复制方法
      document.execCommand("Copy");
      // 复制之后移除这个元素
      document.body.removeChild(input);
      this.$toast("复制成功");
    },
    // 获取 还款账号
    getgetCustomerOrderBank() {
      getCustomerOrderBank().then((res) => {
        if (res.data.code == 0) {
          // console.log(res.data.data);
          this.makeLoansBank = res.data.data;
        }
      });
    },
    // 获取当前应还详情数据
    getgetCurrentLoanDetailList() {
      getCurrentLoanDetailList({ orderId: this.orderId }).then((res) => {
        if (res.data.code == 0) {
          this.ShouldDetails = res.data.data;
        }
      });
    },
    // 获取还款计划表数据 repaymentDate
    getgetLoanDetailList() {
      getLoanDetailList({ orderId: this.orderId }).then((res) => {
        if (res.data.code == 0) {
          let data = res.data.data;
          this.totalperiod = data[data.length - 1].period;
          let arr = [];
          let repaymentDate = data[0].repaymentDate.substring(0, 4);
          // 按照年份 分开放到对应数组中，方便循环
          for (let i = 0; i < data.length; i++) {
            if (data[i].repaymentDate.substring(0, 4) == repaymentDate) {
              arr.push(data[i]);
            } else {
              this.schedule.push(arr);
              repaymentDate = data[i].repaymentDate.substring(0, 4);
              arr = [];
              arr.push(data[i]);
            }
            if (i == data.length - 1) {
              this.schedule.push(arr);
            }
          }
        }
      });
    },
  },
  created() {
    this.orderId = this.$route.query.orderId;
    this.Repayment = JSON.parse(this.$route.query.Orderdata);

    this.getgetCurrentLoanDetailList();
    this.getgetLoanDetailList();
    this.getgetCustomerOrderBank();
  },
};
</script>

<style lang="less" scoped>
.app {
  width: 100%;
  min-height: 100vh;
  background: #f8f8f8;
  padding-bottom: 20px;
  .header {
    width: 100%;
    height: 200px;
    background: linear-gradient(180deg, #a78a48 0%, rgba(167, 138, 72, 0) 100%);
    padding-top: 20px;
    .header-title {
      width: 80%;
      height: 95px;
      margin: auto;
      display: flex;
      align-items: center;
      img {
        width: 47px;
        height: 39px;
        margin-right: 15px;
      }
      .header-text {
        height: 50px;
        p {
          height: 25px;
          font-size: 14px;
          font-weight: 500;
          color: #fff;
        }
      }
    }
    .header-box {
      width: 90%;
      height: 180px;
      margin: auto;
      background: url("../../assets/img/bj2@2x.png") no-repeat;
      background-size: 100% 100%;
      .header-conter {
        width: 90%;
        height: 100px;
        margin: auto;
        padding-top: 20px;
        p {
          font-size: 14px;
          color: rgba(0, 0, 0, 0.6);
        }
        span {
          color: rgba(0, 0, 0, 1);
          margin-left: 10px;
        }
        .header-conterflex {
          display: flex;
          justify-content: space-between;
        }
      }
      .header-line {
        width: 95%;
        height: 3px;
        background: #d3c4a3;
        margin: auto;
      }
      .header-log {
        width: 100%;
        img {
          margin-left: 25px;
          margin-top: 15px;
        }
      }
    }
  }
}
.main {
  width: 90%;
  margin: auto;
  margin-top: 100px;
  .should {
    width: 100%;
    height: 75px;
    background: #f4f0e7;
    border-radius: 10px;
    margin-top: 20px;
    .should-box {
      display: flex;
      justify-content: space-between;
      padding: 10px 20px 5px 20px;
      font-size: 12px;
      color: #a78a48;
      .should-btn {
        width: 100px;
        height: 24px;
        font-size: 12px;
        border: 1px solid #a78a48;
        background: #a78a48;
        color: #ffffff;
      }
    }
    .should-box1 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0px 20px;
      font-size: 12px;
      color: #a78a48;
      .should-btn {
        width: 100px;
        height: 24px;
        font-size: 12px;
        border: 1px solid #a78a48;
        background: #f4f0e7;
        color: #a78a48;
      }
    }
  }
  .details {
    margin: 20px 0;
  }
}
.titlebox {
  display: flex;
  align-items: center;
}
.minbox {
  width: 5px;
  height: 18px;
  background: linear-gradient(180deg, #bca675 0%, #a78a48 100%);
  margin-right: 10px;
}
.popup-box {
  height: 230px;
  .popup-title {
    height: 45px;
    font-size: 16px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .popup-field {
    border-top: 1px solid #f0f0f0;
    .field-btn {
      width: 70px;
      height: 24px;
      color: #a78a48;
      background-color: rgba(167, 138, 72, 0.3);
      border: rgba(167, 138, 72, 0.3);
      border-radius: 12px;
    }
  }
}
.Schedule {
  height: 70px;
  border-bottom: 1px solid #f0f0f0;
  .Schedule-period {
    height: 35px;
    font-size: 16px;
    font-weight: bold;
    color: #000000;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .Schedule-repaymentDate {
    height: 35px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .Schedule-repaymentDate-p {
      max-width: 70%; //显示宽度
      white-space: nowrap; //文本不换行。
      overflow: hidden; //超过部分隐藏
      text-overflow: ellipsis; //超过部分用...代替
    }
  }
}
.repayment-button {
  width: 80px;
  height: 30px;
  color: #efc84f;
  background: rgba(255, 215, 92, 0.2);
  border: rgba(255, 215, 92, 0.2);
}
.Beoverdue-button {
  width: 80px;
  height: 30px;
  color: #a75248;
  background: rgba(167, 82, 72, 0.2);
  border: rgba(167, 82, 72, 0.2);
}
.settle-button {
  width: 80px;
  height: 30px;
  color: #a78a48;
  background: rgba(167, 138, 72, 0.2);
  border: rgba(167, 138, 72, 0.2);
}
</style>
>
